.container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    min-height: 100vh;
}

.item {
    display: flex;
    justify-content: center;
}

.form {
    flex: 1;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
}

input {
    height: 1.2rem;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding-left: 10px;
}
input:focus{
    outline: 1px solid rgb(255, 235, 198);
}

.form-section1,
.form-section2 {
    margin-bottom: 10px;

}

.form-section1 {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr 1fr 2fr;
}

.form-section2 {
    display: grid;
    grid-template-columns: 2fr 10fr;
}

#submit {
    position: absolute;
    right: 0;
    margin-right: 10px;
    margin-top: 20px;
    width: 70px;
    height: 40px;
    padding: 10px;
    background-color: rgb(122, 122, 122);
    border: 1px solid rgb(160, 160, 160);
    border-radius: 10px;
    color: white;
    font-size: 1rem;
}

#submit:hover {
    background-color: lightgray;
    transition: background-color 0.3s;
}