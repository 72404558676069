/* footer.css */

.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    padding: 10px;
    min-width: 200px;
}

.footer-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #f2f2f2;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #ccc;
    text-decoration: none;
}

.footer-section ul li a:hover {
    color: #fff;
}

.footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    color: #bbb;
}
