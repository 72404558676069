.page-body {
    max-width: 1040px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.page-body>.page-icon {
    height: 150px;
    width: 150px;
    margin-bottom: 30px;
}

figure.image {
    width: 100%;
    max-width: 100%;
    margin: 0;
}

figure.image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

figure.media {
    max-width: 80%;
    margin: 1em 0;
}

figure.media oembed {
    width: 100%;
    height: 100%;
    display: block;
}

.page-content h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

.page-content li {
    line-height: 1.8;
}

.page-content hr {
    border: 0;
    height: 0;
    border-top: 1px solid #a8a8a8;
}

.page-content a {
    text-decoration: none;
    color: #ff6f22;
    border-radius: 5px;
    padding: 2px;
}

.page-content a:hover {
    background-color: #ffffff;
    transition: background-color 0.3s;
}

pre {
    position: relative;
}

.copy-button {
    font-weight: 600;
    color: rgb(255, 101, 23);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    border: 0;
    border-left: 3px solid rgb(255, 101, 23);
    border-bottom: 3px solid rgb(255, 101, 23);
    border-radius: 0 0 0 3px;
    padding: 5px;
    top: 0;
    right: 0;
}

.copy-button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.copy-button>* {
    margin-left: 4px;
    margin-right: 4px;
}