@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
   body {
      margin: 0 !important;
   }
}

.main-container {
   --ckeditor5-preview-height: 700px;
   --mobile-preview-height: 80vh;
   font-family: 'Lato';
   width: fit-content;
   margin-left: auto;
   margin-right: auto;
}

.ck-content 
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

.ck-content li {
    line-height: 1.8;
}

.ck-content hr{
    border: 0;
    height: 0;
    border-top: 1px solid #a8a8a8;
}

.ck-content {
   font-family: 'Lato';
   line-height: 1.6;
   word-break: break-word;
}

.ck-content a{
   text-decoration: none;
   color: #ff6f22;
   border-radius: 5px;
   padding: 2px;
}

.ck-content a:hover{
   background-color: #9d9d9d33;
   transition: background-color 0.3s;
}

.editor-container__editor-wrapper {
   display: flex;
   width: fit-content;
}

.editor-container_document-editor {
   border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
   display: flex;
   position: relative;
   box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
   flex-grow: 1;
   width: 0;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
   border-top: 0;
   border-left: 0;
   border-right: 0;
}


@media (min-width: 1025px) {
   .editor-container_document-editor .editor-container__editor-wrapper {
      max-height: var(--ckeditor5-preview-height);
      min-height: var(--ckeditor5-preview-height);
   }
}

@media (max-width: 1024px) {
   .editor-container_document-editor .editor-container__editor-wrapper {
      max-height: var(--mobile-preview-height);
      min-height: var(--mobile-preview-height);
   }
}

.editor-container_document-editor .editor-container__editor-wrapper {
   overflow-y: scroll;
   background: var(--ck-color-base-foreground);
}

.editor-container_document-editor .editor-container__editor {
   margin-top: 28px;
   margin-bottom: 28px;
   height: 100%;
}

@media (min-width: 1025px) {
   .editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
      min-width: calc(50vw - 1px);
      margin-left: 72px;
      margin-right: 72px;
   } 
}

@media (max-width: 1024px) {
   .editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
      min-width: calc(85vw - 1px);
   } 
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
   box-sizing: border-box;
   max-width: calc(100vw -1px);
   min-height: 297mm;
   height: fit-content;
   padding: 20mm 12mm;
   border: 1px hsl(0, 0%, 82.7%) solid;
   background: hsl(0, 0%, 100%);
   box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
   flex: 1 1 auto;
}

.ck-content h3.category {
   font-family: 'Oswald';
   font-size: 20px;
   font-weight: bold;
   color: #555;
   letter-spacing: 10px;
   margin: 0;
   padding: 0;
}

.ck-content h2.document-title {
   font-family: 'Oswald';
   font-size: 50px;
   font-weight: bold;
   margin: 0;
   padding: 0;
   border: 0;
}

.ck-content h3.document-subtitle {
   font-family: 'Oswald';
   font-size: 20px;
   color: #555;
   margin: 0 0 1em;
   font-weight: bold;
   padding: 0;
}

.ck-content p.info-box {
   --background-size: 30px;
   --background-color: #e91e63;
   padding: 1.2em 2em;
   border: 1px solid var(--background-color);
   background: linear-gradient(
         135deg,
         var(--background-color) 0%,
         var(--background-color) var(--background-size),
         transparent var(--background-size)
      ),
      linear-gradient(
         135deg,
         transparent calc(100% - var(--background-size)),
         var(--background-color) calc(100% - var(--background-size)),
         var(--background-color)
      );
   border-radius: 10px;
   margin: 1.5em 2em;
   box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
   font-family: 'Oswald';
   font-style: normal;
   float: right;
   width: 35%;
   position: relative;
   border: 0;
   overflow: visible;
   z-index: 1;
   margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
   content: '“';
   position: absolute;
   top: -37px;
   left: -10px;
   display: block;
   font-size: 200px;
   color: #e7e7e7;
   z-index: -1;
   line-height: 1;
}

.ck-content blockquote.side-quote p {
   font-size: 2em;
   line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
   font-size: 1.3em;
   text-align: right;
   color: #555;
}

.ck-content span.marker {
   background: yellow;
}

.ck-content span.spoiler {
   background: #000;
   color: #000;
}

.ck-content span.spoiler:hover {
   background: #000;
   color: #fff;
}

.ck-content pre.fancy-code {
   border: 0;
   margin-left: 2em;
   margin-right: 2em;
   border-radius: 10px;
}

.ck-content pre.fancy-code::before {
   content: '';
   display: block;
   height: 13px;
   background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
   margin-bottom: 8px;
   background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
   background: #272822;
   color: #fff;
   box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
   background: #dddfe0;
   color: #000;
   box-shadow: 5px 5px 0 #b3b3b3;
}
