.posts-list {
    max-width: 1040px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* 좌측 정렬 */
}

.posts-list h1 {
    text-align: left;
    margin-bottom: 20px;
}

.posts-list ul {
    list-style: none;
    padding: 0;
}

.posts-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex; /* 플렉스 컨테이너로 지정 */
    flex-direction: column; /* 수직 정렬 */
    align-items: flex-start; /* 아이템들을 좌측 정렬 */
}

.posts-list li a {
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    margin-bottom: 5px; /* 제목과 날짜 간격 */
}

.posts-list li a:hover {
    text-decoration: underline;
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #000000;
    color: #fff;
    border: 1px solid #000000;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
