.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* 텍스트가 배경 위로 올라가도록 z-index 설정 */
}

.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.8);
    z-index: 0; /* 배경의 z-index 설정 */
}

.header-text {
    color: white;
    font-size: 18px;
    font-weight: bold;
    position: relative; /* 텍스트의 기본 위치 설정 */
    z-index: 1001; /* 텍스트가 배경 위로 올라가도록 z-index 설정 */
    padding: 10px; /* 텍스트 주변 여백 설정 */
    text-align: center; /* 기본적으로 텍스트를 중앙 정렬 */
}

.header-text h1 {
    margin: 0;
    font-size: 1.2em;
    text-align: left;
}

@media (min-width: 1080px) {
    .header-text {
        text-align: left; /* 화면 너비가 1080px 이상이면 텍스트를 좌측 정렬 */
        margin: 0 auto; /* 가운데 정렬 해제 */
        max-width: 1080px; /* 최대 너비 설정 */
    }
}
