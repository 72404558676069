/* 전체 홈 페이지 스타일 */
.home {
    padding: 50px 20px;
    text-align: center;
    background-color: #f8f8f8;
    max-width: 1040px; /* 전체 페이지의 최대 너비 */
    margin: 0 auto; /* 중앙 정렬 */
    box-sizing: border-box;
}

/* 검색 컨테이너 스타일 */
.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 0 15px;
    box-sizing: border-box;
}

.search-form {
    display: flex;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
}

.search-input {
    flex: 1;
    min-width: 0;
    padding: 10px;
    font-size: 15px;
    border: 2px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
    transition: border-color 0.3s;
}

.search-input:focus {
    border-color: #000;
}

.search-button {
    flex-shrink: 0;
    padding: 10px 20px;
    font-size: 15px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
}

.search-button:hover {
    background-color: #676767;
}

.search-button:active {
    background-color: #676767;
}

/* 최근 문서 섹션 스타일 */
.sectionBody {
    padding: 10px 20px;
    background-color: white;
    text-align: left;
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 980px; /* 게시글 영역의 최대 너비 */
}

.sectionBody h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.sectionBody hr {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.sectionBody .viewAll {
    font-size: 0.8rem; /* 작은 글씨 크기 */
    color: blue; /* 링크 색상 */
    text-decoration: underline; /* 밑줄 */
}

.recent {
    display: block;
    color: #000000;
    text-decoration: none;
    padding: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recent:hover {
    text-decoration: underline;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
    .home {
        padding: 20px 5px;
    }

    .search-form {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    .search-input {
        width: 90%;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .search-button {
        border-radius: 5px;
        width: 95%;
    }

    .sectionBody {
        padding: 10px;
    }
}
